import { Typography } from '@mui/material'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies/main-header'

const BodyTextAtom = (props: TypographiesProps) => {
	// Props
	const { text, sx } = props
	return (
		<Typography fontSize={{ xs: 12, md: 14 }} fontWeight={400} lineHeight="18px" color="text.primary" sx={{ ...sx }}>
			{text}
		</Typography>
	)
}

export default BodyTextAtom
